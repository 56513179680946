<template>
  <div class="form-element form-stack">
    <label 
      for="password-confirmation" 
      class="form-label font-weight-black text-capitalize"
    >
      <slot name="confirm-password-label"></slot>
    </label>
    <v-text-field 
      v-model="confirmedPassword" 
      id="password-confirm" 
      color="white"
      hint="Must be same as password"
      dark
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :error-messages="confirmPasswordErrors"
      @click:append="show = !show"
      @input="$v.confirmedPassword.$touch()"
      @blur="$v.confirmedPassword.$touch()"
    ></v-text-field>
  </div>
</template>

<script>
  import { required, sameAs } from "vuelidate/lib/validators";

  export default {
    name: "ConfirmPasswordField",

    props:{
      
      password:{
        type: [String, Number]
      }
    },

    data(){
      return{
        confirmedPassword: null,
        show: false,
        errorMessages:{
          requiredMessage: 'This field is required',
          confirmPasswordMessage: 'Passwords do not match',
        },
      }
    },

    validations: {

      confirmedPassword: { required, sameAsPassword: sameAs("password") },
    },

    computed:{

      confirmPasswordErrors(){
        const errors = [];
        if (!this.$v.confirmedPassword.$dirty)return errors;
        !this.$v.confirmedPassword.sameAsPassword && errors.push(this.errorMessages.confirmPasswordMessage);
        !this.$v.confirmedPassword.required && errors.push(this.errorMessages.requiredMessage);
        return errors;
      },
    },
    
    watch:{
      password(newVal){
        if(newVal !== this.confirmedPassword){
          this.$emit("confirmed-password-valid", false);
        }else{
          this.$emit("confirmed-password-valid", true);
        }
      },

      confirmedPassword(newVal){
        if(this.$v.confirmedPassword.required && this.$v.confirmedPassword.sameAsPassword){
          this.$emit("confirmed-password-input", newVal);
          this.$emit("confirmed-password-valid", true);
        }else{
          this.$emit("confirmed-password-valid", false);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/plugins/login.scss";

  .form-label{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
  }

</style>