<template>
  <div id="app">
    <div class="left">
      <success-alert
        :success="createUser_Success"
        class="font-weight-black white--text"
      >
        <template #success-message>
          You have successfully created a new user! Check your email for verification
        </template>
      </success-alert>
      <error-alert
        :error="error"
        :error-message="errorMessage"
        @error-closed="error=false"
      ></error-alert>
      <div class="content">
        <img src="@/assets/logoHor.png"/>
        <h2 class="font-weight-bold pa-0">Sign Up</h2>
        <v-form class="form" @submit.prevent="setNewUser">
            <label 
              for="username-signup" 
              class="form-label font-weight-black text-capitalize"
            >
              Username
            </label>
            <v-text-field 
              id="username-signup" 
              v-model="userName" 
              :error-messages="userNameError"
              color="white"
              dark
              hint="Username is required"
              @input="$v.userName.$touch()"
              @blur="$v.userName.$touch()"
            ></v-text-field>
            <email-field
              :dark="true"
              @email-input="email = $event"
              @email-valid="validEmail = $event"
            ></email-field>
            <password-field
              :dark="true"
              @password-input="password = $event"
              @password-valid="validPassword = $event"
            >
              <template #password-label>Password</template>
            </password-field>
            <confirm-password-field
              :password="password"
              @confirmed-password-input="confirmedPassword = $event"
              @confirmed-password-valid="validConfirmedPassword = $event"
            >
              <template #confirm-password-label>Confirm Password</template>
            </confirm-password-field>
            <div class="d-flex align-center">
                <v-btn 
                    id="signUp" 
                    class="text-capitalize" 
                    type="submit" 
                    name="signup" 
                    color="primary"
                    :disabled="invalidForm" 
                    large
                >
                    Sign up
                </v-btn>
                <v-btn
                    color="white"
                    text
                    elevation="0"
                    class="text-capitalize mr-6" 
                    @click="$router.push('/login')"
                >
                    Back
                </v-btn> 
               
            </div>
        </v-form>
      </div>
    </div>
    <img src="@/assets/signup.jpg" class="signup-img"/>
  </div>
</template>
  
<script>
  import { withAsync } from "@/helpers/withAsync";
  import { apiStatus } from "@/api/constants/apiStatus";
  import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
  import { apiObject } from "@/api/api";
  import { createUser } from "@/api/authenticationApi.js";
  import { required } from "vuelidate/lib/validators";
  import ErrorAlert from "@/components/ErrorAlert.vue";
  import SuccessAlert from "@/components/SuccessAlert.vue";
  import EmailField from "@/components/EmailField.vue";
  import PasswordField from "@/components/PasswordField.vue";
  import ConfirmPasswordField from "@/components/ConfirmPasswordField.vue";
  
  export default {
    components: { ErrorAlert, SuccessAlert, EmailField, PasswordField, ConfirmPasswordField },

    name: "SignUser",
    data() {
      return {
        validationError: "This field is required",
        errorMessage: "",
        logIn: true,
        error: false,
        userName: null,
        email: null,
        password: null,
        confirmedPassword: null,
        validEmail: null,
        validPassword: null,
        validConfirmedPassword: null,
        createUser: apiStatus.Idle,
      };
    },

    validations: {

      userName: { required },
    },

    computed:{

      invalidForm() {
        return !(this.userName && this.validEmail === true && this.validPassword === true && this.validConfirmedPassword === true);
      },
      
      userNameError(){
        const errors = [];
        if (!this.$v.userName.$dirty)return errors;
        !this.$v.userName.required && errors.push(this.validationError);
        return errors;
      },
    
      ...apiStatusComputed("createUser")
    },

    methods: {

      goToLogIn() {
        this.logIn = true;
        this.$emit("go-to-login", this.logIn);
      },
      
      async setNewUser() {
        if (!this.$v.$invalid) {
          const payload = {
            username: this.userName,
            email: this.email,
            password: this.password,
            password_confirmation: this.confirmedPassword
          }

          this.createUser =                  apiStatus.Pending;

          const {response, error} =         await withAsync(createUser, payload);

          if(error){       
            console.log(error)         
            this.createUser =                apiStatus.Error;
            this.error =                    true;
            this.errorMessage =             error.response.data.metadata.email ? error.response.data.metadata.email[0] : error.response.data.metadata.password[0];
            this.$emit("error-message", this.errorMessage);
            return this.errorMessage;
          }

          this.createUser =                  apiStatus.Success;
    
          let apiResponse = response.data.data;
        } else {
          this.errorMessage = "An error occurred, please try again"
          this.$emit("error-message", this.errorMessage);
        }
      },
    },
  };
</script>
  
<style scoped lang="scss">
  @import "@/plugins/login.scss";
  .project-login {
    color: #19a7ce;
  }
  .form-label {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
  }
  .signup-img {
    height: 100vh;
    width: 100%;
    position: absolute;
    padding: 0;
  }
  .logo {
    font-family: "Rubik", sans-serif;
    font-size: 80px;
  }
  
  .signup-btn {
    color: #19a7ce !important;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(0 0 0 / 15%)!important;
    color: #ffffffdb !important;
  }
  .form{
    height: 80%;
  }

  @media only screen and (max-width:860px){
    .signup-img{
      height: 180vh;
    }
    .left{
      height: 180vh;
    }
  }

  @media only screen and (max-height:860px){
    .signup-img{
      height: 250vh;
    }
    .left{
      height: 250vh;
    }
  }
</style>
  