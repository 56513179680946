<template>
  <div class="form-element form-stack">
    <label for="email-login" class="form-label font-weight-black text-capitalize">Email</label>
    <v-text-field  
      class="pa-0"
      v-model="email" 
      id="email-login" 
      type="text" 
      name="email"
      :dark="dark"
      :error-messages="emailErrors"
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
    ></v-text-field>
  </div>
</template>

<script>
 import { required, email } from       "vuelidate/lib/validators";

  export default {
    name: "EmailField",

    props:{
      
      dark:{
        type: Boolean
      },
    },

    data(){
      return{
        errorMessages:{
          requiredMessage:               "This field is required",
          emailErrorMessage:             "Must be a valid email",
        },
        email:                            null,
      }
    },
    
    validations: {
      
      email: { required, email }
    },
    
    computed:{ 

      emailErrors(){
        const errors = [];
        if (!this.$v.email.$dirty)return errors;
        !this.$v.email.required && errors.push(this.errorMessages.requiredMessage);
        !this.$v.email.email && errors.push(this.errorMessages.emailErrorMessage);
        return errors;
      },
    },
    
    watch:{

      email(newVal){
        if(this.$v.email.email){
          this.$emit("email-input", newVal);
          this.$emit("email-valid", true);
        }else{
          this.$emit("email-valid", false);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/plugins/login.scss";

  .form-label{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
  }

</style>