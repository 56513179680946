<template>
  <v-alert
    :value="success"
    :prominent="prominent"
    shaped
    dismissible
    elevation="24"
    :class="['success-alert', { 'blue-background': successMessage === 'Your email address has already been verified.' }]"
    transition="slide-x-reverse-transition"
    @input="emitChange($event)"
  >
    <slot name="success-message"></slot>
  </v-alert>
</template>

<script>
export default {
  name: "SuccessAlert",

  props: {

    success: {
      type: Boolean,
      required: true,
    },

    prominent: {
      type: Boolean,
      default: true,
    },

    successMessage:{
      type: String
    }
  },
  
  methods:{
    
    emitChange(event){
      this.$emit("success-closed", event);
    }
  },

  beforeMount() {
    this.$emit("success-status", this.success);
  },
};
</script>

<style scoped>
  .success-alert {
    font-family: "Inter", sans-serif !important;
    font-weight: 600 !important;
    background-color: rgb(6, 200, 113) !important;
  }

  .blue-background {
    background-color: #19A7CE !important;
  }
</style>
