<template>
  <div class="form-element form-stack">
    <label 
      for="password-signup" 
      class="form-label font-weight-black text-capitalize"
    >
      <slot name="password-label"></slot>
    </label>
    <v-text-field 
      class="pa-0"
      id="password-signup" 
      v-model="password" 
      :error-messages="passwordErrors" 
      :dark="dark"
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show = !show"
      @input="$v.password.$touch()"
      @blur="$v.password.$touch()"
    ></v-text-field>
  </div>
</template>

<script>
  import { required, minLength } from "vuelidate/lib/validators";

  export default {
    name: "PasswordField",

    props:{

      dark:{
        type: Boolean
      }
    },

    data(){
      return{
        show: false,
        password: null,
        errorMessages:{
          requiredMessage: 'This field is required',
          minLength: 'Minimum length 8 characters', 
        },
      }
    },

    validations: {

      password: { required, minLength: minLength(8) },
    },

    computed:{

      passwordErrors(){
        const errors = [];
        if (!this.$v.password.$dirty)return errors;
        !this.$v.password.required && errors.push(this.errorMessages.requiredMessage);
        !this.$v.password.minLength && errors.push(this.errorMessages.minLength);
        return errors;
      },
    },

    watch:{
      
      password(newVal){
        if(this.$v.password.required && this.$v.password.minLength){
          this.$emit("password-input", newVal);
          this.$emit("password-valid", true);
        }else{
          this.$emit("password-valid", false);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/plugins/login.scss";

  .form-label{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
  }

</style>